// import React from 'react';
// import {Route, Link} from 'react-router-dom';
// import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
// import routes from '../../routes';





import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import { withBreadcrumbs } from './withBreadcrumbs';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';

const UserBreadcrumb = ({ match }) =>
    <span>{match.params._id}</span>; // use match param userId to fetch/display user name

const breadcrumbRoutes = [
  { path: '/users', breadcrumb: 'Users' },
  { path: '/users/:_id', breadcrumb: 'Details'},
    { path: '/wagers', breadcrumb: 'Wagers'},
    { path: '/games', breadcrumb: 'Games'},
    { path: '/dashboard', breadcrumb: 'Dashboard'},


    { path: 'something-else', breadcrumb: ':)' },
];



const Breadcrumbs = ({breadcrumbs}) => (
    <Breadcrumb>



        {breadcrumbs.map((item, i) => {
            let len = breadcrumbs.length
            let {breadcrumb, path, match} = item

            if(len-1 ===  i){
                return(
                    <BreadcrumbItem active>
                        <span key={path}>
                        {breadcrumb}
                        </span>
                    </BreadcrumbItem>
                )

            }
            else {
                return(
                    <BreadcrumbItem active>
                        <span key={path}>
                        <Link to={match.url}>
                        {breadcrumb}
                        </Link>

                        </span>
                    </BreadcrumbItem>
                )}

        })
        }
    </Breadcrumb>
);

export default withBreadcrumbs(breadcrumbRoutes)(Breadcrumbs);


// const findRouteName = url => routes[url];
//
// const getPaths = (pathname) => {
//   const paths = ['/'];
//
//   if (pathname === '/') return paths;
//
//   pathname.split('/').reduce((prev, curr, index) => {
//     const currPath = `${prev}/${curr}`;
//     paths.push(currPath);
//     return currPath;
//   });
//   return paths;
// };
//
// const BreadcrumbsItem = ({ match}) => {
//   const routeName = findRouteName(match.url);
//   console.log(routeName, 'routeName');
//   if (routeName) {
//     return (
//       match.isExact ?
//         (
//           <BreadcrumbItem active>{routeName}</BreadcrumbItem>
//         ) :
//         (
//           <BreadcrumbItem>
//             <Link to={match.url || ''}>
//               {routeName}
//             </Link>
//           </BreadcrumbItem>
//         )
//     );
//   }
//   return null;
// };
//
// const Breadcrumbs = ({location : {pathname}, match}) => {
//   const paths = getPaths(pathname);
//   const items = paths.map((path, i) => <Route key={i++} path={path} component={BreadcrumbsItem}/>);
//   return (
//     <Breadcrumb>
//       {items}
//     </Breadcrumb>
//   );
// };
//
// export default props => (
//   <div>
//     <Route path="/:path" component={Breadcrumbs} {...props} />
//   </div>
// );

/**
 * @About
 * This file handle response of all api.
 */
import { Button, notification } from 'antd';

//Response filter
function responseFilter(response) {
    console.log(response, "response")
    var serverResponseStatus = response.status;
    var serverResponseData = (typeof response.data != 'undefined')
        ? response.data
        : ((typeof response.error != 'undefined') ? response.error : null);

    if (serverResponseData.statusCode === 0 && serverResponseData.error && serverResponseData.error.errorCode === 11) {
        console.log("token expire");
        localStorage.removeItem('accessToken');
        // window.location.href = '/';
    }
    if(response.data.statusCode === 0) {

        notification.error({
            message: 'Error',
            description: response.data.error.responseMessage

        })
        if(response.data.error.errorCode === 11)
            localStorage.removeItem('accessToken')
         serverResponseData =undefined;
    }
       return  serverResponseData;

}
export {
    responseFilter
}
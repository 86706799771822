import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Modal, Button, notification} from 'antd';
import {Service} from "../../../services/apiServices";
import {responseFilter} from "../../../_helper/responceHendler";

const DeleteModal = forwardRef((props, ref) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState({})
    useImperativeHandle(ref, () => ({
        deleteUser(props) {
            setIsModalVisible(true);
            setData(props)

        }


    }));


    const deleteUser = (e) => {
        console.log(data, "ttttttttttttttttt")
        let params = {
            'userId' : data._id,
        }
        Service.deleteUser(params).then(res => {
            let serverResponseData  = responseFilter(res);
            if(serverResponseData !== undefined){
                notification.success({
                    message: 'Success',
                    description: serverResponseData.responseData.message

                })
                setIsModalVisible(false);
                props.getUser();
            }
        })
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        deleteUser(props)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            {/*<Button type="primary" onClick={showModal}>*/}
            {/*    Open Modal*/}
            {/*</Button>*/}
            <Modal  visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Are you sure you want to delete?</p>
            </Modal>
        </>
    );
});

export default DeleteModal


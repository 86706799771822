import React, { Component, useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Table } from "reactstrap";
import { Link } from "react-router-dom";
import EditStore from "../../views/Pages/EditStore";
import { Service } from "../../services/apiServices";
import { responseFilter } from "../../_helper/responceHendler";
import { logDOM } from "@testing-library/react";
import { notification, Pagination } from "antd";
import moment from "moment";
import { Select } from "antd";

const { Option } = Select;
function Games() {
  let [loading, setLoading] = useState(true);
  let [status, setStatus] = useState("");
  let [data, setData] = useState([]);
  let [parms, setParms] = useState({
    pageNo: 1,
    limit: 10,
    sKey: "",
    gameType: "",
    status: "",
    isAdmin: 1,
  });
  const getGames = () => {
    Service.getGames(parms).then((res) => {
      let serverResponseData = responseFilter(res);
      if (serverResponseData !== undefined) {
        setData(serverResponseData.responseData);
        setLoading(false);
      }
    });
  };

  const changeStatus = (game) => {
    let params = {
      gameId: game._id,
      action: !game.WagerAllow,
    };
    Service.changeStatus(params)
      .then((res) => {
        let serverResponseData = responseFilter(res);
        if (serverResponseData !== undefined) {
          notification.success({
            message: "Success",
            description: serverResponseData.responseData.message,
          });
          getGames();
        }
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.message,
        });
      });
  };

  useEffect(() => {
    if (parms.sKey.length > 2 || parms.sKey.length === 0) getGames(parms);
  }, [
    parms.pageNo,
    parms.pageSize,
    parms.sKey,
    parms.limit,
    parms.gameType,
    parms.status,
  ]);

  useEffect(() => {
    getGames();
  }, [parms]);

  const handlePageChange = (page, pageSize) => {
    setParms({ ...parms, pageNo: page, limit: pageSize });
  };
  const handleSearch = (e) => {
    // console.log("dkjhd",e.target.value.trim());
    setParms({ ...parms, sKey: e.target.value.trim(), pageNo: 1 });
  };

  function selectType(value) {
    setParms({ ...parms, gameType: value, pageNo: 1 });
  }
  function selectStatus(value) {
    setParms({ ...parms, status: value, pageNo: 1 });
    console.log(value);
  }

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <span className="flex-grow-1">Games</span>

          <Select
            className="pr-2"
            defaultValue="ALL"
            style={{ width: 120 }}
            onChange={selectStatus}
          >
            <Option value="">ALL</Option>
            <Option value="Scheduled">Scheduled</Option>
            <Option value="Final">Final</Option>
            <Option value="Cancel">Cancel</Option>
          </Select>

          <Select
            className="pr-2"
            defaultValue="ALL"
            style={{ width: 120 }}
            onChange={selectType}
          >
            <Option value="">ALL</Option>
            <Option value="mlb">MLB</Option>
            <Option value="nhl">NHL</Option>
            <Option value="nfl">NFL</Option>
            <Option value="nba">NBA</Option>
            <Option value="ncaaFootball">CFB</Option>
            <Option value="ncaaBasketball">CBB</Option>
            <Option value="soccer">SOCCER</Option>
          </Select>

          <div className="form-outline">
            <input
              title="Home Team, Away Team"
              type="search"
              id="sKey"
              className="form-control pt-1 pb-1"
              placeholder="Search..."
              onChange={(e) => handleSearch(e)}
              aria-label="Search"
            />
          </div>
        </CardHeader>
        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                <th className="border-top-0">S. No.</th>
                <th className="border-top-0">Away Team</th>
                <th className="border-top-0">Home Team</th>
                <th className="border-top-0">League</th>
                <th className="border-top-0">Over Under</th>
                <th className="border-top-0">Point Spread</th>
                <th className="border-top-0">Status</th>
                <th className="border-top-0">Date Time</th>
                <th className="border-top-0">Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.result?.games?.length > 0 ? (
                data?.result?.games?.map((game, i) => {
                  return (
                    <tr key={game._id}>
                      <td>{(parms.pageNo - 1) * parms.limit + 1 + i}.</td>
                      <td>{game.AwayTeam}</td>
                      <td>{game.HomeTeam}</td>
                      <td>{game.GameType.toUpperCase()}</td>
                      <td>{game.OverUnder}</td>
                      <td>{game.PointSpread}</td>
                      <td>{game.Status}</td>
                      <td>{game.DateTime}</td>
                      <td>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={game.WagerAllow}
                            onChange={() => changeStatus(game)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">
                    No results found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="text-center">
            <Pagination
              className="m-auto"
              current={parms.pageNo}
              total={data?.result?.total}
              defaultPageSize={parms.limit}
              onChange={handlePageChange}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
export default Games;

/**
 * @About
 * This file is entry point of config
 */

import _ from 'lodash';
import { devSetting, stagSetting, prodSetting } from './setting';

//defaut setting

let siteSetting ;

switch (process.env.REACT_APP_ENV) {
    case "prod":
    case "production":
        console.log(prodSetting,"ooooooo");
        siteSetting = prodSetting;
        break;
    case "stag":
    case "staging":
        siteSetting =stagSetting;
        break;
    case "dev":
    case "development":
        siteSetting = devSetting;
        break;

   
    default:
        siteSetting = prodSetting;
}
export default siteSetting;
import React, {Component} from 'react';
import {Row, Col, Card, CardHeader, CardBody, Input, FormGroup, Label, Form, FormText, Button} from 'reactstrap';

function MyProfile() {
    return (
        <div className="animated fadeIn">
            <Card>
              <CardHeader>My Profile</CardHeader>
              <CardBody>
                <Form action="" method="post" className="form-horizontal">
                  <FormGroup row>
                  <Col md="6">
                      <FormGroup row className="mb-3 pb-2">
                        <Col md="3">
                            <Label htmlFor="hf-name">Name</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text" id="hf-name" name="hf-name" placeholder="Enter Name..." value="Jon Deo"/>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mb-3 pb-2">
                        <Col md="3">
                            <Label htmlFor="hf-email">Email</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="email" id="hf-email" name="hf-email" placeholder="Enter Email..." value="jondeo@gmail.com" />
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mb-3 pb-2">
                        <Col md="3">
                            <Label htmlFor="hf-text">Phone</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text" id="hf-text" name="hf-text" placeholder="Enter Phone number..." value="9876543210"/>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mb-3 pb-2">
                        <Col md="3">
                            <Label htmlFor="hf-text">Address</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <textarea type="text" id="nf-address" name="nf-address" placeholder="Enter Store Address.." value="54 Bodean Road, Unit 2 Greenmount Retail Park, London" className="form-control"/>
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mb-3 pb-2">
                        <Col xs="12" md="9" className="offset-3">
                            <Button color="black" className="px-4">Submit</Button>
                        </Col>
                      </FormGroup>
                  </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
        </div>
    )
}
export default MyProfile;

import React, {Component, useReducer, useEffect} from 'react';
import {Container, Row, Col, CardGroup, Card, CardBody, Button, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router-dom'
import {EmailValidation, PasswordValidation} from '../../../components/common/Validation'
import {Service} from '../../../services/apiServices'
import {responseFilter} from "../../../_helper/responceHendler";

const Login = (props) => {
  const history = useHistory()
    const [inputValue, setInputValue] = useReducer((state, newState) => ({...state, ...newState}),{
        email:'',
        emailError:'',
        password:'',
        passwordError:'',
        errorMessage: ''
    })
    const HandelChange = (e) => {
        setInputValue({
            [e.target.name] : e.target.value,
            [e.target.name + 'Error'] : '',
            errorMessga:''
        })
    }


    const SubmitLogin = () => {
      let params = {
            'email' : inputValue.email,
            'password' : inputValue.password,
            'deviceToken' : "12345",
            'deviceID' : "123456",
            'deviceTypeID' :  "1"

      }
        Service.login(params).then(res => {
            let serverResponseData  = responseFilter(res);
            if(serverResponseData !== undefined){
                localStorage.setItem('accessToken', serverResponseData.responseData.accessToken)
                // history.push("/dashboard")
                window.location.href = '/dashboard';
            }
      })
    }


  
    return (
      <div className="app flex-row align-items-center login-admin">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <div className="admin-logo text-center mb-4">
                <img src={require('../../../assets/img/logo.png')} alt="logo" />
              </div>
              <CardGroup className="mb-4">
                <Card className="p-4">
                  <CardBody>
                    <h2 className="mb-1 text-center font-weight-bold">Login</h2>
                    <p className="text-muted text-center">Sign In to your account</p>
                    <div className="mb-3">
                      <Input type="text" placeholder="Username" name="email" value={inputValue.email} onChange={(e) => HandelChange(e)}/>
                      <div className="text-danger small">{inputValue.emailError}</div>
                    </div>

                    <div className="mb-4">
                      <Input type="password" placeholder="Password" name="password" value={inputValue.password} onChange={(e) => HandelChange(e)}/>
                      <div className="text-danger small">{inputValue.passwordErrorError}</div>
                    </div>
                    
                    <Row>
                      <Col xs="12">
                        <button  className="px-4 login-btn" onClick={SubmitLogin}>Login</button>
                      </Col>
                      <Col xs="12" className="text-center mt-3">
                        <Link color="link" className="px-0" to="/forgot-password">Forgot password?</Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

export default Login;

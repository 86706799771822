import React, {Component, useEffect, useState} from 'react';
import {Card, CardHeader, CardBody, Table} from 'reactstrap';
import {Link} from 'react-router-dom'
import {Service} from "../../services/apiServices";
import {responseFilter} from "../../_helper/responceHendler";
import { Pagination } from 'antd';
import {getData} from "../../_helper/appUtils";

function Wagers() {
    let [loading, setLoading] = useState(true);
    let [data, setData] = useState({});
    let [parms, setParms] = useState({
        pageNo : 1,
        limit : 10,
        sKey : ''
    });
    const getWagers = (parms) => {
        Service.getWagers(parms).then(res => {
            let serverResponseData = responseFilter(res);
            console.log("serverResponseData",res);
            if (serverResponseData !== undefined) {
                setData(serverResponseData.responseData);
                setLoading(false);


            }
        })
    }

    useEffect(() => {
        getWagers();
    },[])

    useEffect(() => {
        if(parms.sKey.length > 2 || parms.sKey.length === 0)
            getWagers(parms);

    }, [parms.pageNo, parms.pageSize, parms.sKey, parms.limit]);

    const handlePageChange =(page, pageSize) =>{
        setParms({...parms, pageNo : page, limit : pageSize})
    }
    const handleSearch =(e) =>{
        setParms({...parms, sKey : e.target.value, pageNo : 1})
    }
    return (
        <div className="animated fadeIn">
            <Card>
              <CardHeader className="d-flex justify-content-between">
                  Wagers
                  {/*<div className="form-outline">*/}
                  {/*    <input type="search" id="sKey" className="form-control pt-1 pb-1" placeholder="Type query" onChange={e => handleSearch(e)} aria-label="Search"/>*/}
                  {/*</div>*/}
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                  <tr>
                    <th className="border-top-0">S. No.</th>
                    <th className="border-top-0">Wager Creator</th>
                    <th className="border-top-0">Phone</th>
                    <th className="border-top-0">Selected Team</th>
                    <th className="border-top-0">Opponent Team</th>
                  </tr>
                  </thead>
                    <tbody>
                    {

                        data?.wagers?.map((wager, i) => {
                            return(
                                <tr>
                                    <td>{(((parms.pageNo - 1) * parms.limit ) + 1) + i}.</td>
                                    {
                                        wager.wagerCreatedId?
                                            <td> <Link to={`/users/${wager.wagerCreatedId._id}`}>{wager?.wagerCreatedId?.firstName + " " + wager?.wagerCreatedId?.lastName}</Link></td> :
                                            <td>N/A</td>
                                    }

                                    <td>{getData(wager.wagerCreatedId?.countryCode + "" +wager.wagerCreatedId?.phone)}</td>
                                    <td>{wager.selectedTeamName}</td>
                                    <td>{wager.opponentTeamName}</td>


                                </tr>
                            )
                        })
                    }

                    </tbody>

                </Table>
                  <div className="text-center">
                      <Pagination className="m-auto" current={parms.pageNo} total={data?.total} defaultPageSize={parms.limit} onChange={handlePageChange}/>
                  </div>
              </CardBody>
            </Card>
        </div>
    )
}
export default Wagers;

import React, { Component, useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { BrowserRouter, Link } from "react-router-dom";
import { Service } from "../../services/apiServices";
import { responseFilter } from "../../_helper/responceHendler";
import { Pagination, notification } from "antd";
import moment from "moment";
import { getData } from "../../_helper/appUtils";
import Skeleton from "react-loading-skeleton";
import DeleteModal from "./modal/DeleteUser";
import { param } from "jquery";

function Users() {
  const childRef = useRef();
  let [loading, setLoading] = useState(false);

  let [data, setData] = useState({});
  let [parms, setParms] = useState({
    pageNo: 1,
    limit: 10,
    sKey: "",
    sortValue: "created",
    dir: 1,
  });
  let [modal, setModal] = useState({
    visible: false,
    user: {},
  });
  const getUsers = (parms) => {
    setLoading(true);
    Service.getUsers(parms).then((res) => {
      let serverResponseData = responseFilter(res);
      if (serverResponseData !== undefined) {
        setData(() => serverResponseData.responseData);
        setLoading(false);
      }
    });
  };

  const blockUser = (user) => {
    const updatedUser = { ...user, status: !user.status };
    Service.blockUser({
      userId: updatedUser._id,
      action: updatedUser.status ? 1 : 0,
    }).then((res) => {
      const serverResponseData = responseFilter(res);
      if (serverResponseData !== undefined) {
        const updatedData = { ...data };
        const index = updatedData.users.findIndex(
          (u) => u._id === updatedUser._id
        );
        if (index >= 0) {
          updatedData.users[index] = updatedUser;
          setData(updatedData);
        }
      }
    });
  };
  

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (parms.sKey.length > 2 || parms.sKey.length === 0) getUsers(parms);
  }, [parms.pageNo, parms.limit, parms.sKey,parms.pageSize]);
  
  const handlePageChange = (page,pageSize) => {
    console.log(page);
    setParms({ ...parms, pageNo: page,limit:pageSize });
  };
  
  const handleSearch = (e) => {
    setParms({ ...parms, sKey: e.target.value.trim(), pageNo: 1 });
  };

  const _onClick = (e, firstName) => {
    if (!firstName) {
      e.preventDefault();
    }
  };

  const deleteUser = (user) => {
    setModal({ visible: true, user: user });
  };
  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader className="d-flex justify-content-between">
          Users
          <div className="form-outline">
            <input
              title="Name,Email,Phone"
              type="search"
              id="sKey"
              className="form-control pt-1 pb-1"
              placeholder="Search..."
              onChange={(e) => handleSearch(e)}
              aria-label="Search"
            />
          </div>
        </CardHeader>

        {!loading && (
          <CardBody>
            <Table responsive>
              <thead>
                <tr>
                  <th className="border-top-0">S. No.</th>
                  <th className="border-top-0">Name</th>
                  <th className="border-top-0">Email</th>
                  <th className="border-top-0">Phone</th>
                  <th className="border-top-0">DOB</th>
                  <th className="border-top-0">Block</th>
                  <th className="border-top-0">Delete</th>
                </tr>
              </thead>
              <tbody>
            {data?.users?.length > 0 ? (
              data.users.map((user, i) => {
                return (
                  <tr key={i}>
                    <td>{(parms.pageNo - 1) * parms.limit + 1 + i}.</td>
                    <td>
                      <Link
                        to={`/users/${user._id}`}
                        onClick={(e) => _onClick(e, user.firstName)}
                      >
                        {getData(user.firstName + " " + user.lastName)}
                      </Link>
                    </td>
                    <td>{user.email}</td>
                    <td>
                      {user.phone
                        ? user.countryCode + "" + user.phone
                        : "N/A"}
                    </td>
                    <td>
                      {user.dob
                        ? moment(user.dob, "MMM/DD/YYYY").format("MM/DD/YYYY")
                        : "N/A"}
                    </td>

                    <td>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={user.status}
                          onChange={(e) => {
                            e.preventDefault();
                            blockUser(user);
                          }}
                        />
                        <span className="slider round"></span>
                      </label>
                    </td>

                    <td>
                      <ul className="mb-0 list-inline">
                        <li className="list-inline-item">
                          <i
                            className="cursor-pointer icon-trash text-danger"
                            title="Delete"
                            onClick={() =>
                              childRef.current.deleteUser(user)
                            }
                          ></i>
                        </li>
                      </ul>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                No results found.
                </td>
              </tr>
            )}
            {loading && <Skeleton count={20} />}
          </tbody>
            </Table>

            <div className="text-center">
              <Pagination
                className="m-auto"
                current={parms.pageNo}
                total={data?.total}
                defaultPageSize={parms.limit}
                onChange={handlePageChange}
              />
            </div>
          </CardBody>
        )}
        {!modal.visible && (
          <DeleteModal ref={childRef} getUser={() => getUsers(parms)}/>
        )}
      </Card>
    </div>
  );
}
export default Users;

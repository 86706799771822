import siteSetting from "../config/settings/index";

export default{
    ///Authorization
    AUTH: siteSetting.api.AUTH,
    GET_USERS: siteSetting.api.url + "user/userList",
    GET_USER : siteSetting.api.url + "admin/getUser",
    FORGET_PASSWORD : siteSetting.api.url + "admin/forgetPassword",
    VERIFY_OTP : siteSetting.api.url + "admin/verifyOTP",
    RESET_PASSWORD : siteSetting.api.url + "admin/resetPassword",

    USER_LOGIN: siteSetting.api.url + "admin/login",
    BLOCK_USER : siteSetting.api.url + "admin/blockUser",
    DELETE_USER : siteSetting.api.url + "admin/deleteUser",

    DASHBOARD : siteSetting.api.url + "admin/dashboard",
    GET_WAGERS: siteSetting.api.url + "admin/getWagers",
    GET_USER_WAGER : siteSetting.api.url + "admin/getUserWagers",
    GET_GAMES :  siteSetting.api.url + "game/getGames",
    CHANGE_GAME_STATUS : siteSetting.api.url + "game/changeStatus",

    CHANGE_STATE_STATUS : siteSetting.api.url + "state/changeStatus",
    GET_STATES : siteSetting.api.url + "state/list",
}

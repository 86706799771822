export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'Users',
      url: '/users',
      icon: 'icon-user',
    },
    {
      name: 'Wagers',
      url: '/wagers',
      icon: 'icon-list',
    },
    {
      name: 'Games',
      url: '/games',
      icon: 'icon-layers',
    },
    {
      name: 'States',
      url: '/states',
      icon: 'icon-layers',
    }
    // {
    //   title: true,
    //   name: 'Theme',
    //   wrapper: {            // optional wrapper object
    //     element: 'span',      // required valid HTML5 element tag
    //     attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
    //   },
    //   class: ''             // optional class names space delimited list for title item ex: "text-center"
    // },
  ]
};

// 1 = scheduled  , 2 = completed, 3 = cancled, drow = 4


function getGameStatus(parms) {
    let status = ''
    switch (parms) {

        case 1:
            status = "Scheduled";
            break;
        case 2:
            status = "Completed";
            break;
        case 3:
            status = "Canceled";
            break;
        case 4:
            status = "Draw";
            break;

    }
return status;
}

function getData(data){
    if(data === '' || data === undefined || data === " " || !data, data === "undefinedundefined"){
        return "N/A"

    }
    else{
        return  data;
    }
}
export {
    getGameStatus,
    getData
}
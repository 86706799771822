import React, {Component, useReducer, useState} from 'react';
import {Container, Row, Col, CardGroup, Card, CardBody, Button, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import {Link, useHistory} from 'react-router-dom'
import {Service} from "../../../services/apiServices";
import {responseFilter} from "../../../_helper/responceHendler";
import {notification} from "antd";


function ForgotPassword() {
  const history = useHistory()

  const [inputValue, setInputValue] = useState({
    email:'',
    emailError:'',
    password:'',
    passwordError:'',
    errorMessage: ''
  })
  const HandelChange = (e) => {
    setInputValue({...inputValue,
      [e.target.name] : e.target.value,
      [e.target.name + 'Error'] : '',
    })
  }


  const submit = () => {
    let params = {
      'email' : inputValue.email,

    }
    Service.forgetPassword(params).then(res => {
      let serverResponseData  = responseFilter(res);
      if(serverResponseData !== undefined){
        sessionStorage.setItem("email" ,inputValue.email)
        notification.success({
          message: 'Success',
          description: serverResponseData.responseData.message
        })
        history.push("/submit-otp")
      }
    })
  }


  return (
      <div className="app flex-row align-items-center login-admin">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
                <div className="admin-logo text-center mb-4">
                    <img src={require('../../../assets/img/logo.png')} alt="logo" />
                </div>
              <CardGroup className="mb-4">
                <Card className="p-4">
                  <CardBody>
                    <h2 className="mb-4 text-center font-weight-bold">Forgot Password</h2>
                    <InputGroup className="mb-3">
                      <Input type="text" placeholder="Email" name="email" value={inputValue.email} onChange={(e) => HandelChange(e)}/>
                    </InputGroup>
                    <Row>
                      <Col xs="12">
                        <Button color="black w-100 btn-lg" className="px-4" onClick={submit}>Submit</Button>
                      </Col>
                      <Col xs="12" className="text-center mt-3">
                        <Link color="link" className="px-0" to="/">Back to login</Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );

}

export default ForgotPassword;

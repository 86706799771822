import React, { Component, useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Table } from "reactstrap";
import { Link } from "react-router-dom";
import EditStore from "../../views/Pages/EditStore";
import { Service } from "../../services/apiServices";
import { responseFilter } from "../../_helper/responceHendler";
import { logDOM } from "@testing-library/react";
import { notification, Pagination } from "antd";
import moment from "moment";

function States() {
  let [loading, setLoading] = useState(true);
  let [status, setStatus] = useState("");
  let [data, setData] = useState({});
  let [parms, setParms] = useState({
    pageNo: 1,
    limit: 10,
    sKey: "",
  });
  const getStates = () => {
    Service.getStates(parms).then((res) => {
      let serverResponseData = responseFilter(res);
      if (serverResponseData !== undefined) {
        setData(serverResponseData.responseData);
        setLoading(false);
      }
    });
  };

  const changeStatus = (e) => {
    let params = {
      _id: e._id,
      action: e.status ? 0 : 1,
    };
    Service.changeStateStatus(params).then((res) => {
      let serverResponseData = responseFilter(res);
      if (serverResponseData !== undefined) {
        // console.log(serverResponseData, "serverResponseData")
        notification.success({
          message: "Success",
          description: serverResponseData.responseData.message,
        });
        getStates();
      }
    });
  };

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (parms.sKey.length > 2 || parms.sKey.length === 0) getStates(parms);
  }, [parms.pageNo, parms.pageSize, parms.sKey, parms.limit]);

  const handlePageChange = (page, pageSize) => {
    setParms({ ...parms, pageNo: page, limit: pageSize });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setParms({ ...parms, sKey: e.target.value.trim(), pageNo: 1 });
  };

  

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader className="d-flex justify-content-between">
          States
          <div className="form-outline">
            <input
              title="Name, Abbreviation"
              type="search"
              id="sKey"
              className="form-control pt-1 pb-1"
              placeholder="Search..."
              onChange={(e) => handleSearch(e)}
              aria-label="Search"
            />
          </div>
        </CardHeader>
        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                <th className="border-top-0">S. No.</th>
                <th className="border-top-0">Name</th>
                <th className="border-top-0">Abbreviation</th>
                <th className="border-top-0">Status</th>
              </tr>
            </thead>

            <tbody>
              {data?.result?.states?.length ? (
                data.result.states.map((game, i) => {
                  return (
                    <tr key={i}>
                      <td>{(parms.pageNo - 1) * parms.limit + 1 + i}.</td>
                      <td>{game.name}</td>
                      <td>{game.abbreviation}</td>
                      <td>
                        <label className="switch">
                          <input type="checkbox" checked={game.status} />
                          <span
                            className="slider round"
                            onClick={() => changeStatus(game)}
                          ></span>
                        </label>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                  No results found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="text-center">
            <Pagination
              className="m-auto"
              current={parms.pageNo}
              total={data?.result?.total}
              defaultPageSize={parms.limit}
              onChange={handlePageChange}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
export default States;

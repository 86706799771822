import axios from "axios";
import Api from "./api";

export const Service = {
    login,
    forgetPassword,
    verifyOTP,
    resetPassword,
    dashboard,
    getUsers,
    getUser,
    blockUser,
    deleteUser,
    getWagers,
    getGames,
    changeStatus,

    getStates,
    changeStateStatus
};
let accessToken  =  localStorage.getItem('accessToken')
const headersApplicationJson = {
    "Content-Type": "application/json",
    "accessToken" :accessToken
};
axios.defaults.headers.common["Authorization"] = `${Api.AUTH}`;

function login(params) {
    return axios.post(Api.USER_LOGIN, params, {
        headers: headersApplicationJson,
    });
}
function forgetPassword(params) {
    return axios.post(Api.FORGET_PASSWORD, params, {
        headers: headersApplicationJson,
    });
}
function verifyOTP(params) {
    return axios.post(Api.VERIFY_OTP, params, {
        headers: headersApplicationJson,
    });
}
function resetPassword(params) {
    headersApplicationJson.accessToken = sessionStorage.getItem('accessToken')
    return axios.post(Api.RESET_PASSWORD, params, {
        headers: headersApplicationJson,
    });
}

function dashboard(params) {
    return axios.get(Api.DASHBOARD, {
        headers: headersApplicationJson,
    });
}

function getUsers(params) {
    let config = {
        headers: headersApplicationJson,
        params: params
    }
    return axios.get(Api.GET_USERS, config);
}

function getUser(params) {
    let config = {
        headers: headersApplicationJson,
        params: params
    }
    return axios.get(Api.GET_USER, config);
}

function blockUser(params) {
    return axios.put(Api.BLOCK_USER, params, {
        headers: headersApplicationJson,
    });
}

function deleteUser(params) {
    console.log(params, "params")
    let config = {
        headers: headersApplicationJson,
        params: params
    }
    return axios.delete(Api.DELETE_USER, config);
}



function getWagers(params) {
    let config = {
        headers: headersApplicationJson,
        params: params
    }
    return axios.get(Api.GET_WAGERS, config);
}

function getGames(params) {
    let config = {
        headers: headersApplicationJson,
        params: params
    }
    return axios.get(Api.GET_GAMES, config);
}

function changeStatus(params) {
    return axios.put(Api.CHANGE_GAME_STATUS,  params, {
        headers: headersApplicationJson,

    });
}


function getStates(params) {
    let config = {
        headers: headersApplicationJson,
        params: params
    }
    return axios.get(Api.GET_STATES, config);
}

function changeStateStatus(params) {
    return axios.put(Api.CHANGE_STATE_STATUS,  params, {
        headers: headersApplicationJson,

    });
}
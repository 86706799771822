import React, {Component, useEffect, useState, forwardRef, useRef, useImperativeHandle} from 'react';
import {Card, CardHeader, CardBody, Table} from 'reactstrap';
import {Link} from 'react-router-dom'
import {Service} from "../../services/apiServices";
import {responseFilter} from "../../_helper/responceHendler";
import {Button, Pagination, Tabs} from 'antd';
import moment from "moment";
import {getGameStatus} from "../../_helper/appUtils";
import Modal from "antd/es/modal/Modal";
import Skeleton from "react-loading-skeleton";

const {TabPane} = Tabs;

function UserDetails(props) {
    const childRef = useRef();
    let [loading, setLoading] = useState(true);
    let [wagerLoading, setWagerLoading] = useState(true);
    let [data, setData] = useState({});
    let [state, setSate] = useState({
        invited: [],
        user: {}
    });
    let [parms, setParms] = useState({
        userId: props.match.params._id,
        wagerType: 1,
        pageNo: 1,
        limit: 10,
        sKey: ''
    });

    const [isModalVisible, setIsModalVisible] = useState(false);
    const getWagers = () => {
        setWagerLoading(true);
        Service.getWagers(parms).then(res => {
            let serverResponseData = responseFilter(res);
            if (serverResponseData !== undefined) {
                setWagerLoading(false);
                setData(serverResponseData.responseData);
            }
        })
    }
    const getUser = () => {
        Service.getUser(parms).then(res => {
            let serverResponseData = responseFilter(res);
            if (serverResponseData !== undefined) {
                setLoading(false);
                setSate({...state, user: serverResponseData.responseData});
            }
        })
    }

    useEffect(() => {
        getWagers();
        getUser()
    }, []);

    useEffect(() => {
        getWagers(parms);
    }, [parms.pageNo, parms.pageSize, parms.wagerType]);

    const handlePageChange = (page, pageSize) => {
        setParms({...parms, pageNo: page, limit: pageSize})
    }

    function callback(key) {
        setParms({...parms, wagerType: key})
    }


    const showModal = (invited) => {
        setIsModalVisible(true);
        setSate({...state, invited: invited})
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="animated fadeIn">
            <Card>
                <CardHeader className="d-flex justify-content-between">User Details
                </CardHeader>
                <CardBody>
                    {
                        loading && <Skeleton count={5}/>
                    }
                    {
                        !loading &&
                        <Table dark>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Wager Record</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>{state?.user?.firstName + " " + state?.user?.lastName}</td>
                                <td>{state.user.email}</td>

                                <td>
                                    <button type="button" className="btn btn-success"
                                            onClick={() => childRef.current.getAlert(state.user)}>View
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <ModalWagerRecoard ref={childRef}/>
                        </Table>
                    }

                    <CardHeader className="d-flex justify-content-between">Wager History
                    </CardHeader>
                    <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="Created" key="1">
                            {
                                !wagerLoading && data.wagers &&
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <th className="border-top-0">S. No.</th>
                                        <th className="border-top-0">Creator</th>
                                        <th className="border-top-0">Selected Team</th>
                                        <th className="border-top-0">Opponent Team</th>
                                        <th className="border-top-0">Date</th>
                                        <th className="border-top-0">Status</th>
                                        <th className="border-top-0">View</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data?.wagers?.map((wager, i) => {

                                            return (
                                                <tr>
                                                    <td>{(((parms.pageNo - 1) * parms.limit) + 1) + i}.</td>
                                                    <td>{wager?.wagerCreatedId?.firstName}</td>
                                                    <td>{wager.selectedTeamName}</td>
                                                    <td>{wager.opponentTeamName}</td>
                                                    <td>{moment(wager.created).format('MM/DD/YYYY')}</td>
                                                    <td>{getGameStatus(wager.gameStatus)}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-success"
                                                                onClick={() => showModal(wager.invited)}>Invited
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>

                            }
                            {
                                wagerLoading && <Skeleton count={10}/>
                            }
                            <div className="text-center m-auto">
                                <Pagination className="m-auto" current={parms.pageNo} total={data?.total}
                                            onChange={handlePageChange}/>
                            </div>
                        </TabPane>
                        <TabPane tab="Invited" key="2">
                            {
                              !wagerLoading &&  data.wagers &&
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <th className="border-top-0">S. No.</th>
                                        <th className="border-top-0">Creator</th>
                                        <th className="border-top-0">Selected Team</th>
                                        <th className="border-top-0">Opponent Team</th>
                                        <th className="border-top-0">Date</th>
                                        <th className="border-top-0">Game Status</th>
                                        <th className="border-top-0">Accepted</th>
                                        <th className="border-top-0">Wager Amount</th>
                                        <th className="border-top-0">Winning Amount</th>
                                        <th className="border-top-0">Commission</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data?.wagers?.map((wager, i) => {
                                            let user = wager.invited.filter(user => user.userId?._id == props.match.params._id)
                                            return (
                                                <tr>
                                                    <td>{(((parms.pageNo - 1) * parms.limit) + 1) + i}.</td>
                                                    <td>{wager?.wagerCreatedId?.firstName}</td>
                                                    <td>{wager.opponentTeamName}</td>
                                                    <td>{wager.selectedTeamName}</td>
                                                    <td>{moment(wager.created).format('MM/DD/YYYY')}</td>
                                                    <td>{getGameStatus(wager.gameStatus)}</td>
                                                    <td>{user[0]?.accepted ? "Yes" : "No"}</td>
                                                    <td>{user[0]?.wagerAmount}</td>
                                                    <td>{user[0]?.winningPrice}</td>
                                                    <td>{user[0]?.commission}</td>

                                                </tr>

                                            )
                                        })
                                    }


                                    </tbody>
                                </Table>
                            }
                            {
                                wagerLoading && <Skeleton count={10}/>
                            }
                            <div className="text-center m-auto">
                                <Pagination className="m-auto" current={parms.pageNo} total={data?.total} onChange={handlePageChange}/>
                            </div>
                        </TabPane>

                    </Tabs>

                </CardBody>
            </Card>
            <Modal title="Invited User" visible={isModalVisible} onCancel={handleCancel} footer={null} width={600}>
                <Table>
                    <thead>
                    <tr>
                        <th className="border-top-0">S. No.</th>
                        <th className="border-top-0">Name</th>
                        <th className="border-top-0">Accepted</th>
                        <th className="border-top-0">Wager Amount</th>
                        <th className="border-top-0">Winning Amount</th>
                        <th className="border-top-0">Commission</th>
                    </tr>
                    </thead>
                    <tbody>
                    {state.invited.length &&
                    state.invited.map((user, i) => {
                        return (
                            <tr>
                                <td>{i + 1}</td>
                                <td>{user?.userId?.firstName}</td>
                                <td>{user.accepted ? "Yes" : "NO"}</td>
                                <td>{user.wagerAmount}</td>
                                <td>{user.winningPrice}</td>
                                <td>{user.commission}</td>
                            </tr>
                        )
                    })

                    }

                    </tbody>
                </Table>
            </Modal>
        </div>
    )
}


const ModalWagerRecoard = forwardRef((props, ref) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState('');

    useImperativeHandle(ref, () => ({
        getAlert(props) {
            setIsModalVisible(true);
            setData(props);
        }

    }));

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Modal title="Wager Record" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <Table>
                    <thead>
                    <tr>
                        <th className="border-top-0">Total Wager</th>
                        <th className="border-top-0">Total Win</th>
                        <th className="border-top-0">Total Loss</th>
                        <th className="border-top-0">Total Win Amount($)</th>
                        <th className="border-top-0">Total Loss Amount($)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data &&
                    <tr>
                        <td>{data.wagerRecord.totalWager}</td>
                        <td>{data.wagerRecord.won}</td>
                        <td>{data.wagerRecord.loss}</td>
                        <td>{data.wagerRecord.wonAmount}</td>
                        <td>{data.wagerRecord.lossAmount}</td>
                    </tr>


                    }
                    </tbody>
                </Table>
            </Modal>
        </>
    );
});

export default UserDetails;

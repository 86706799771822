import React, {Component} from 'react';

class Footer extends Component {
  render() {
    const year = new Date().getFullYear()
    return (
      <footer className="app-footer">
        <span><a href="https://genesisui.com">Copyright</a> &copy; {year}</span>
        <span className="ml-auto">Powered by WagerFans</span>
      </footer>
    )
  }
}

export default Footer;

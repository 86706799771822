import React, {Component, useState} from 'react';
import {Link, useHistory} from 'react-router-dom'

import {
  NavbarToggler,
  Nav,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,NavItem
} from 'reactstrap';

function Header() {
  const history = useHistory()
  let [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () =>{
    setDropdownOpen(!dropdownOpen)
  }
  const LogOut = () => {
    localStorage.removeItem('accessToken')
    history.push("/")
  }

  const sidebarToggle =(e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  const sidebarMinimize =(e)=> {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  const mobileSidebarToggle =(e) =>{
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={mobileSidebarToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
        <div className="navbar-brand">
          <Link to="/dashboard">
            <img src={require('../../assets/img/logo.png')} alt="logo" />
          </Link>
        </div>
        <NavbarToggler className="d-md-down-none" onClick={sidebarToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
        <Nav className="ml-auto" navbar>
        <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle nav>
            <div className="menu-dropdown">
              <strong className="pr-3">Admin <i className="cursor-pointer icon-arrow-down"></i></strong>
            </div>
          </DropdownToggle>
          <DropdownMenu right>
            {/*<DropdownItem className="dropdown-item" onClick={() => {window.location.href='/profile'}}><i className="fa fa-user"></i> Profile</DropdownItem>*/}
            {/*<DropdownItem className="dropdown-item" href="/change-password" ><i className="fa fa-wrench"></i> Change Password</DropdownItem>*/}
            <DropdownItem className="dropdown-item" onClick={LogOut}><i className="fa fa-lock"></i> Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        </Nav>
      </header>
    );

}

export default Header;

import React, {Component, useEffect, useState} from 'react';
import {Bar, Line} from 'react-chartjs-2';
import {
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardBody,
    ButtonGroup,
    ButtonDropdown
} from 'reactstrap';
import {Service} from "../../services/apiServices";
import Skeleton from "react-loading-skeleton";
import {responseFilter} from "../../_helper/responceHendler";
import {Link} from "react-router-dom";

function Dashboard() {
    let loop = [1, 2, 3, 4]
    let [loading, setLoading] = useState(true);
    let [data, setData] = useState({});
    const dashboard = () => {
        Service.dashboard().then(res => {
            let serverResponseData = responseFilter(res);
            console.log("dashbord_data_outside",res.data);
            if (serverResponseData !== undefined) {
                console.log("dashbord_data",res.data);
                setLoading(false);
                setData(serverResponseData.responseData);
            }
        })
    }

    useEffect(() => {
        dashboard();
    }, []);
    return (
        <>
            {loading &&
            <div className='row'>
                {loading && loop.map((i) => {
                    return (
                        <Col xs="12" sm="4" lg="4" key={i}>
                            <Link to="/admin-user">
                                <Card className="p-3 card-list">
                                    <CardBody className="pb-0">
                                        <h4 className="mb-2 text-info"><Skeleton square={true}/></h4>
                                        <p><Skeleton square={true}/></p>
                                        <i className=""></i>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    )
                })}
            </div>
            }
            {
                !loading &&
                <div className="animated fadeIn">
                    <Row>
                        <Col xs="12" sm="4" lg="4">
                            <Link to="/users"> <Card className="p-3 card-list">
                                <CardBody className="pb-0">
                                    <h4 className="mb-2 text-info">{data.users}</h4>
                                    <p className="text-dark">Total Users</p>
                                    <i className="fa fa-users text-info"></i>
                                </CardBody>
                            </Card></Link>
                        </Col>

                        <Col xs="12" sm="4" lg="4">
                            <Link to="/wagers">
                                <Card className="p-3 card-list">
                                    <CardBody className="pb-0">
                                        <h4 className="mb-2 text-danger">{data.wagers}</h4>
                                        <p className="text-dark">Total Wagers</p>
                                        <i className="icon-layers text-danger"></i>
                                    </CardBody>
                                </Card></Link>
                        </Col>

                        <Col xs="12" sm="4" lg="4">
                            <Link to="/games">
                                <Card className="p-3 card-list">
                                    <CardBody className="pb-0">
                                        <h4 className="mb-2 text-primary">{data.games}</h4>
                                        <p className="text-dark">Total Games</p>
                                        <i className="icon-list text-primary"></i>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>


                    </Row>

                </div>
            }
        </>
    )

}

export default Dashboard;

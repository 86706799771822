/**
 * @About
 * This file mange environment of the project
 */

//dev
export const devSetting = {
    api: {
        url: process.env.REACT_APP_DEV_API_URL,
        AUTH: process.env.REACT_APP_BASIC_AUTH,
        mode:'cors'

    },
}

//stag
export const stagSetting = {
    api: {
        url:process.env.REACT_APP_STAG_API_URL,
        AUTH: process.env.REACT_APP_BASIC_AUTH,
        mode:'cors'
    },
}

//prod
export const prodSetting = {
    api: {
        url:process.env.REACT_APP_PROD_API_URL,
        AUTH: process.env.REACT_APP_BASIC_AUTH,
        mode:'cors'
    },
}

//local
export const localhostSetting = {
    api: {
        url: process.env.REACT_APP_LOCAL_API_URL,
        mode:'cors'
    },
}
import React, {Component} from 'react';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
  useHistory,
} from "react-router-dom";
import {Container} from 'reactstrap';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Breadcrumb from './components/Breadcrumb/Breadcrumbs';
import Footer from './components/Footer/Footer';
import Dashboard from './views/Pages/Dashboard';
import Users from './views/Pages/Users';
import UserDetails from './views/Pages/UserDetails';

import Wagers from './views/Pages/Wagers';
import Games from './views/Pages/Games';
import States from './views/Pages/States';

import MyProfile from './views/Pages/MyProfile';
import ChangePassword from './views/Pages/ChangePassword';
import {logDOM} from "@testing-library/react";



class Full extends Component {

  render() {
    return (
      <div className="app">
        <Header  {...this.props}/>
        <div className="app-body">
          <Sidebar {...this.props}/>
          <main className="main">
            <Breadcrumb {...this.props} />
            <Container fluid>
              <Switch>

                <Route path="/dashboard" name="Dashboard" component={Dashboard}/>
                <Route path="/wagers" name="Wagers" component={Wagers}/>
                <Route path="/games" name="Games" component={Games}/>
                <Route path="/states" name="Games" component={States}/>
                <Route path="/users/:_id" name="UserDetails" component={UserDetails}/>
                <Route path="/users" name="Users" component={Users}/>

                <Route path="/profile" name="Colors" component={MyProfile}/>

                {/* <Redirect from="/" to="/dashboard"/> */}
              </Switch>
            </Container>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Full;
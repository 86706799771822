import React from 'react';
import ReactDOM from 'react-dom';

// Styles
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './assets/scss/style.scss'
import './assets/scss/core/_dropdown-menu-right.scss'
import "antd/dist/reset.css";

import './views/index.css'


import Full from './Full'
// import history from './history'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {browserHistory } from 'react';
import Login from './views/Pages/Login/Login'
import Dashboard from "./views/Pages/Dashboard";
import ForgotPassword from './views/Pages/Login/ForgotPassword'
import Page404 from './views/Pages/Login/Page404'
import ChangePassword from "./views/Pages/ChangePassword";
import ResetPassword from "./views/Pages/Login/ResetPassword";
import SubmitOTP from "./views/Pages/Login/SubmitOTP";

function PrivateRoute ({component: Component, authed, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to={{pathname: '/', state: {from: props.location}}} component={Login} />}
        />
    )
}
ReactDOM.render((


    <Router history={browserHistory}>

        <Switch>
            {/*<PrivateRoute authed={ localStorage.getItem("accessToken")? true : false} path='/dashboard' component={Dashboard} />*/}

            <Route exact path="/" name="Login Page" component={Login}/>
            {/*<Route path="/dashboard" name="Dashboard" component={Dashboard}/>*/}
            <Route exact path="/forgot-password" name="Login Page" component={ForgotPassword}/>
            <Route exact path="/reset-password" name="Login Page" component={ResetPassword}/>
            <Route exact path="/submit-otp" name="Submit OTP" component={SubmitOTP}/>
             <Route exact path="/404" name="Page 404" component={Page404}/>
            <Full />
    </Switch>
    </Router>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
